@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Work+Sans&display=swap');

.loader {
    display: block;
    height: 100%;
    width:100%;
    margin:0;
    padding:0;
}
.loader-img {
    width: 100px;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
}
.loader-text {
    position:block;
    padding-top: 5px;
    text-align: center;
    font-size: 10px;
    font-weight: normal;
    color: #ced4da;
    font-family: 'Work Sans', sans-serif;
}
