@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Work+Sans&display=swap');

.button{
    width: 100%;
    height: 30px;
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 5px;
    border-color: #ffd166;
    border: 1px solid #ffd166;
    background-color: #ffd166;
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #0a2e36 ;
    }
.button:hover {
    color: white ;
}

