@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Work+Sans&display=swap');

.edit-player{
    display: flex;
    justify-content: space-between;
}
.edit-player__img{
    width: 30px;
    font-size: 25px;
    margin: 10px 10px 10px 10px;
    cursor: pointer;
}
.edit-player__img:hover{
    width: 32px;
}
.table-download{
    padding-left: 3%;
    padding-right: 3%;
}

.intro-scorecard{
    margin-top: 5px;
    display: block;
}

.title-scorecard__img{
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5%;
}

.title-scorecard{
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #0a2e36;
    font-family: 'Crimson Pro', serif;
}

.legend-scorecard{
    padding-top: 5px;
    padding-bottom: 15px;
    text-align: center;
    font-size: 10px;
    font-weight: normal;
    color: #0a2e36;
    font-family: 'Work Sans', sans-serif;
}

table.customTable {
    width: 500px;
    margin-right: auto;
    margin-left:auto;
    padding-left: 2%;
    padding-right: 2%;
    background-color: #FFFFFF;
    border-collapse: collapse;
    table-layout: fixed;
    border-width: 1px;
    border-color: #0A2E36;
    border-style: solid;
    color: #0A2E36;
  }
  
  table.customTable td, table.customTable th {
    border-width: 1px;
    border-color: #0A2E36;
    border-style: solid;
    padding: 5px 1px 5px 1px;
  }
  
  table.customTable thead {
    font-family: 'Crimson Pro', serif;
  }
  table.customTable tbody {
    font-family: 'Work Sans', sans-serif;
  }
  table.customTable tfoot {
    background-color: #F8F8F8;
    font-family: 'Work Sans', sans-serif;
  }

.scorecard_title--standard {
    font-size: 14px;
    background-color: white;
    width: 6%;
}
.scorecard_title--white {
    font-size: 14px;
    background-color: #f1faee;
    width: 6%;
}
.scorecard_title--yellow {
    font-size: 14px;
    background-color: #ffd166;
    width: 6%;
}
.scorecard_title--blue {
    font-size: 14px;
    background-color: #118ab2;
    width: 6%;
}
.scorecard_title--pink {
    font-size: 14px;
    background-color: #ef486f;
    width: 6%;
}
.scorecard_title--player {
    font-size: 14px;
    background-color: #EEF9FC;
    width: 14.5%;
}
.edit_player{
    width: 100%;
    height: 1.5em;
    padding-bottom: 2px;
    padding-top: 2px;
    font-family: 'Crimson Pro', serif;
    font-size: 14px;
    border: 0px solid;
    border-color: none;
    background-color: transparent;
    text-align: center;
}
.edit_player::-webkit-input-placeholder {
    font-family: 'Crimson Pro', serif;
    font-size: 12px;
    font-weight: normal;    

}
.edit_player:focus{
    outline:none;
}

.scorecard_input--hole {
    font-size: 18px;
    font-weight: bold;    
    background-color: white;
    text-align: center;
}
.scorecard_input--white {
    width: 95%;
    font-size: 12px;   
    background-color: #f1faee;
    text-align: center;
}
.scorecard_input--yellow {
    width: 95%;
    font-size: 12px;   
    background-color: #ffd166;
    text-align: center;
}
.scorecard_input--blue {
    font-size: 12px;   
    background-color: #118ab2;
    text-align: center;
}
.scorecard_input--pink {
    font-size: 12px;   
    background-color: #ef486f;
    text-align: center;
}
.scorecard_input--standard {
    font-size: 12px;   
    background-color: white;
    text-align: center;
}
.scorecard_input--player {
    font-size: 14px;   
    background-color: #EEF9FC;
    text-align: center
}
.edit_hole{
    width: 1.5em;
    height: 1.5em;
    border: 0px solid;
    border-color: #0a2e36;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    background-color: transparent;
    text-align: center;
    font-weight: bold;    
}
.edit_hole--bogey {
    width: 1.5em;
    height: 1.5em;
    border: 1px solid;
    border-color: #0a2e36;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    background-color: transparent;
    text-align: center;
    font-weight: bold;  
}
.edit_hole--doubleBogey {
    width: 1.5em;
    height: 1.5em;
    border: 2px solid;
    border-color: #0a2e36;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    background-color: transparent;
    text-align: center;
    font-weight: bold;  
}
.edit_hole--birdie {
    width: 1.5em;
    height: 1.5em;
    border: 1px solid;
    border-color: #0a2e36;
    border-radius: 50%;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    background-color: transparent;
    text-align: center;
    font-weight: bold;  
}
.edit_hole--doubleBirdie {
    width: 1.5em;
    height: 1.5em;
    border: 2px solid;
    border-radius: 50%;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    background-color: transparent;
    text-align: center;
    font-weight: bold;  
}

.edit_hole:focus{
    outline:none;
}
.edit_hole::-webkit-outer-spin-button,
.edit_hole::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.edit_hole::-webkit-input-placeholder{
    font-weight: normal;

}
.results_standard{
    font-size: 14px;
    font-weight: bolder;    
    background-color: white;
    text-align: center; 
}
.results_starts{
    font-size: 14px;
    font-weight: bolder;    
    background-color: white;
    text-align: center; 
}
.results_player{
    font-size: 14px;
    font-weight: bolder;    
    background-color: white;
    text-align: center; 
}


@media (max-width: 550px) {
    .title-scorecard{
        font-size: 22px;
    }
    .scorecard_title--standard {
        font-size: 10px;
    }
    .scorecard_title--player {
        font-size: 9px;
    }
    .scorecard_title--white {
        font-size: 9px;
    }
    .scorecard_title--yellow {
        font-size: 9px;
    }
    .scorecard_title--blue {
        font-size: 9px;
    }
    .scorecard_title--pink {
        font-size: 9px;
    }
    .scorecard_input--hole {
        font-size: 14px;
    }
    .scorecard_input--white {
        font-size: 9px;   
    }
    .scorecard_input--yellow {
        font-size: 9px;   
    }
    .scorecard_input--blue {
        font-size: 9px;   
    }
    .scorecard_input--pink {
        font-size: 9px;   
    }
    .scorecard_input--standard {
        font-size: 10px;   
    }
    .scorecard_input--player {
        font-size: 12px;
    }
    .edit_player{
        font-size: 12px;
    }
    .edit_player::-webkit-input-placeholder {
        font-size: 12px;
    }
    .edit_hole{
        font-size: 14px;  
    }
    .edit_hole--bogey {
        font-size: 14px; 
    }
    .edit_hole--doubleBogey {
        font-size: 14px; 
    }
    .edit_hole--birdie {
        font-size: 14px; 
    }
    .edit_hole--doubleBirdie {
        font-size: 14px; 
    }
    .results_standard{
        font-size: 10px;
    }
    .results_starts{
        font-size: 9px;
    }
    .results_player{
        font-size: 14px;
    }
    .title-scorecard__img{
        width: 30%;
    }
    table.customTable {
        width: 100%;
      }
}