@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Work+Sans&display=swap');

body{
    background-color: white;
}
h1 {
    text-align: center;
    padding-bottom: 15px;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 30px;
    font-weight: bold;
    color: #0a2e36;
    font-family: 'Crimson Pro', serif;
}
h2 {
    text-align: center;
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: normal;
    color: #0a2e36;
    font-family: 'Work Sans', sans-serif;
}
.title-courses{
    display: block;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.title-courses__img{
    width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
}
.club-list{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-left: 10%;
    margin-right: 10%;
}

.club-card {
    height: 230px;
    width: 250px;    
    border-radius: 5px;
    margin: 15px 15px 15px 15px;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 2.5px 15px 0 rgb(0 0 0 / 10%);
    cursor: pointer;
    margin-bottom: 20px;
    background-color: #fff;
}

.club-card__img{
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.club-card__details{
    display: block;
}
.club-card__details--img{
    width: 35px;
    height: 35px;
    display: block;
    object-fit: contain;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-left: 15px;
}
.club-card__details--title{
    font-family: 'Crimson Pro', serif;
    font-weight: bold; 
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    font-size: 16px;
    color: #0a2e36;
}
.club-card__details--title:hover{
    color: #14cc60ff;
}

.club-card__details--subtitle{
    font-family: 'Work Sans', sans-serif;
    margin-top: -10px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 12px;
    color: #0a2e36;
    text-decoration: none !important;
}


@media (max-width: 520px) {
    .title-courses__img{
        width: 40%;
    }
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 14px;
    }
}