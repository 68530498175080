@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Work+Sans&display=swap');

.popupContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
    text-align: center;
}
.popupContent-button_close {
      width: 30px;
      font-size: 25px;
      color: #c0c5cb;
      align-self: flex-end;
      background-color: transparent;
      border: none;
      margin: 10px 10px 10px 10px;
      cursor: pointer;

}

.popupContent-img {
      width: 70px;
      margin-bottom: 15px;
}

.popupContent-text     {
    text-align: center;
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: normal;
    color: #0a2e36;
    font-family: 'Work Sans', sans-serif;
}

.popupContent-button_number {
      background-color: #14cc60ff;
      font-family: 'Work Sans', sans-serif;
      margin-bottom: 10px;
      border: none;
      border-radius: 3px;
      width: 200px;
      padding: 14px;
      font-size: 16px;
      color: #0a2e36;      
      box-shadow: 0 2.5px 15px 0 rgb(0 0 0 / 15%);
      cursor: pointer;
}
    .popupContent-button_number:hover{
        background-color: #ffd166
    }
  .cardContainer {
    margin-top: 10px;
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: center;
    background-color: #ffd166;
    text-align: center;
    border-radius: 5px;

  }

  .result-name {
    text-align: center;
    margin-top: 15px;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 28px;
    font-weight: 800;
    color: #0a2e36;
    font-family: 'Work Sans', sans-serif;
  }
  
  .result-explanation {
    text-align: center;
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 14px;
    font-weight: normal;
    color: #0a2e36;
    font-family: 'Work Sans', sans-serif;
  }